import type { TSportEvent } from 'markets-store'
import { EVENT_ALLOWED_STATES } from 'markets-store/constants'
import { useMarketsStore } from './useMarketsStore'

/**
 * Получение списка всех спортивных событий в статусе live (идут прямо сейчас)
 * @example
 * const liveEvents = useLiveEvents()
 */
export function useLiveEvents(): ComputedRef<TSportEvent[]> {
  const MS = useMarketsStore()

  return computed(() =>
    // @ts-expect-error IndexedActual is magic
    MS.sportEvents.live.filter((event: TSportEvent) =>
      EVENT_ALLOWED_STATES.includes(event.state),
    ),
  )
}
